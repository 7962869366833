.imgGrad {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background: green;
    background: url("./bg5.png") no-repeat center center;
    background-size:cover;
    width: 100%;
    height: 100%;

}

.imgGrad .imgImg{
    // width: 500%;
}