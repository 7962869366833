@font-face {
  font-family: "AN";
  src: url("/fonts/AN.woff2") format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: "Geneva";
  src: url("/fonts/Geneva.woff2") format("woff2");
  font-weight: 100;
}


:root {
  --accent1: #ff0002ff;
  --accent2: #000000;
  --accent3: #ffffff;
  --accent4: #2bff00;
  --accent5: #7fa7e2;
  --text-color: #000000;
  --background-color: #c2c3c4;
  --background-overlay: #c2c3c483;
  --modal-background: rgba(300, 300, 300, 0.8);
  --header-font: "AN", serif;
  --body-font: Arial, serif;
  --link-font: "AN", serif;
  --title-font: "AN", serif;
  --category-font: "AN", serif;
  --columns: 4;
  --insetMaxWidth: 60%;
  --width: 100vw;
  --height: 100vh;
  --max-width: 100vw;
  --max-height: 90vw;
  --post-width: 85vw;
  --category-margin: 3vw;
  --element-width:182px;
  --transition: all 0.3s ease;

  /* media queries */
  --modal-wrapper-margin: 3vw;
  --modal-wrapper-margin-large: 8vw;
  --modal-wrapper-margin-med: 10vw;
  --modal-wrapper-margin-small: 20vw;
  --modal-wrapper-margin-xsmall: 27vw;
  --modal-wrapper-margin-xxsmall: 34vw;
  
  --modal-margin: 20vw;
  --modal-margin-med: 13vw;
  --modal-margin-small: 0;

  --page-gutter: 30px;
  --page-gutter-side: 40px;
  --page-gutter-side-med: 15px;
  
  --element-width:120px;
  --element-width-med: 140px;
  --element-height-small:72px;

  --subpage-margin: 17vh;
  --subpage-margin-med: 20vh;

  


}

@custom-media --mobile (max-width: 1080px);
@custom-media --tablet (min-width: 600px);
@custom-media --desktop (min-width: 768px);
@custom-media --huge (min-width: 1500px);
