@import "./reset.css";
@import "./variables.css";

/* @import './util.scss'; */
html {
  /* background: url("./img/bg3.png"), #c2c3c4; */
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: cover;
  z-index: -2000;
  height: 100vh;
}

body {
  min-height: calc(100% +200px);
  background-color: var(--background-color);
  background-size: contain;
  font-family: var(--body-font);
  line-height: 1.6em;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: none;
  overflow: hidden;
  transition: var(--transition);
}

a {
  color: var(--text-color);
  transition: var(--transition);
}

a:hover {
  color: var(--accent1);
}

p {
  font-size: 21px;
  line-height: 1.25em;
}

p.lAlign {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
  font-family: var(--header-font);
  font-weight: 100;
}

h1 {
  font-size: 3em;
  line-height: 1em;
}

h2 {
  font-size: 2.7em;
  line-height: 1em;
}

h4 {
  font-size: 2.2em;
  line-height: 1.5em;
}

h5 {
  font-size: 1.4em;
}

sup {
  font-size: 18px;
  color: var(--accent2);
  text-shadow: 1px 1px 9px var(--accent4);
}

::selection {
  text-shadow: 15px 9px 15px var(--accent4);
}

::-moz-selection {
  text-shadow: 1px 1px 9px var(--accent2);
}

img {
  /* box-shadow: 6px 6px 20px #000; */
  /* filter: blur(10px); */
  transition: var(--transition);
}

img:hover {
  /* filter: invert(1); */
}

/* components */
.header {
  top: var(--page-gutter);
  height: var(--modal-wrapper-margin);
}

nav {
  top: calc(50vh - 100px);
  position: fixed;
}

nav>div {
  cursor: pointer;
  font-size: 3em;
  line-height: 1.2em;
  font-family: var(--link-font);
  /* transition: all 0.3s ease; */
}

nav>div:hover {
  color: var(--accent1);
  transition: var(--transition);
}

.navSub {
  width: var(--width);
  height: var(--height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  bottom: var(--page-gutter);
  /* background: red; */
}

.header,
.footer,
nav {
  position: fixed;
  left: var(--page-gutter-side);
  z-index: 9999;
  width: --element-width;
}

nav {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.modalWrap {
  width: calc(var(--width) - var(--page-gutter-side)*2);
  min-height: 100%;
  position: absolute;
  top: 0;
  left: var(--page-gutter-side);
  margin-top: var(--modal-wrapper-margin);
}

.modalBlur {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--background-color);
  box-shadow: 0 calc(var(--modal-wrapper-margin) - 25px) 20px var(--background-color);
  z-index: 999;
  width: 100%;
  height: var(--modal-wrapper-margin);
}

.info,
.teaching,
.modal {
  position: absolute;
  padding: 3em;
  width: calc(100% - (var(--modal-margin) * 2));
  height: calc(100% - var(--modal-wrapper-margin));
  overflow: auto;
  margin-left: var(--modal-margin);
  z-index: 500;
  color: var(--text-color);
}

.info,
.teaching {
  top: var(--subpage-margin);
  height: calc(100% - var(--subpage-margin));
}

.info>div,
.teaching>div {
  max-width: 860px;
  text-align: justify;
}

.info p,
.teaching p {
  font-size: 1.8em;
  line-height: 1.3em;
}

.info p a,
.teaching p a {
  text-decoration: underline;
}

.title {
  color: var(--text-accent);
  font-size: 13vw;
  font-family: var(--title-font);
  font-weight: 400;
  line-height: 14vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Project*/
.projTitle {
  color: var(--accent3);
}

.headerMaterials {
  color: var(--accent3);
  padding-top: 0.5em;
  font-size: 1.5em;
}

.btnCont {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;



  /* background: red; */
}

.btnVint {
  /* background: red; */
  border-style: outset;
  transition: var(--transition);
  background-color: var(--background-color);
  /* min-width: 26em; */
  /* min-height: 8em; */
  text-align: center;
  text-shadow: 15px 9px 15px var(--accent4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btnVint:hover {
  background: #dfdfdf;
  text-shadow: 1px 1px 20px var(--accent4);


}

.btnVint a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75em 3em;

}

/* 404 */

.notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: 100vh;
  width: 100%;
  text-align: center;
  line-height: 2em;
}

/* Mobile Friendly Adjustments */

html {
  --scrollbarBG: var(--accent3);
  --scrollbarBGHover: var(--accent1);
  --thumbBG: black;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarBG) var(--thumbBG);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbarBG);
  border: 0px;
  transition: var(--transition);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbarBGHover);
}

/* Buttons */
*::-webkit-scrollbar-button:single-button {
  width: 0;
  height: 0;
  display: none;
}

@media screen and (max-width: 767px) {
  .smallOnly {
    display: inherit !important;
  }

  .mediumOnly {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .smallOnly {
    display: none !important;
  }

  .mediumOnly {
    display: flex !important;
  }
}

/* media queries */
@media screen and (max-width: 1024px) {
  .header {
    top: var(--page-gutter-side-med);
  }

  .footer {
    bottom: var(--page-gutter-side-med);
  }

  nav {
    display: flex;
    flex-flow: row wrap;
    gap: 0 var(--category-margin);
    font-size: 10px;
    top: 0;
    left: var(--element-width-med);
    padding-top: var(--page-gutter-side-med);
    width: calc(100% - var(--page-gutter) - var(--element-width-med));
    height: var(--element-height-small);
    padding-left: var(--page-gutter-side-med);
    justify-content: flex-end;
    align-items: center;
  }

  .btnCont {
    flex-direction: column;
  }

  .modal,
  .info,
  .teaching {
    width: 100%;
    margin-left: 0;
    height: calc(100% - var(--modal-wrapper-margin) - 9em);
    padding-bottom: 4em;

  }

  /* .info,
  .teaching {
    top: 100px;
  } */

  .modalWrap {
    margin-top: var(--modal-wrapper-margin-large);
    height: calc(100% - var(--modal-wrapper-margin-large) - 3em);

  }

  .modalBlur {
    box-shadow: 0 calc(var(--modal-wrapper-margin-large) - 20px) 16px var(--background-color);
    height: calc(var(--modal-wrapper-margin-large) - 10px);
  }

  .info>div,
  .teaching>div {
    height: calc(100% + var(--modal-wrapper-margin-large));
  }

}

@media screen and (max-width: 768px) {
  nav {
    display: flex;
    flex-flow: row wrap;
    gap: 0 var(--category-margin);
    font-size: 10px;
    top: 0;
    left: var(--element-width-med);
    padding-top: var(--page-gutter-side-med);
    width: calc(100% - var(--page-gutter) - var(--element-width-med));
    height: var(--element-height-small);
    padding-left: var(--page-gutter-side-med);
    justify-content: flex-end;
    align-items: center;
  }

  .header,
  .footer,
  nav {
    text-shadow: 3px 3px 5px var(--accent3);
  }

  .modal {
    margin-left: var(--modal-margin-small);
    padding: var(--page-gutter-side-med);
    padding-top: calc(var(--modal-wrapper-margin-med) + var(--page-gutter-side-med)*2);
  }


  .info,
  .teaching {
    padding: calc(var(--page-gutter-side-med) * 2);
    height: 80%;
  }

  .info p,
  .teaching p {
    font-size: 1.6em;
    line-height: 1.2em;
  }

  .modalWrap {
    margin-top: var(--modal-wrapper-margin-med);

  }

  .modalBlur {
    box-shadow: 0 calc(var(--modal-wrapper-margin-med) - 20px) 16px var(--background-color);
    height: var(--modal-wrapper-margin-med);
  }

}

@media screen and (max-width: 480px) {
  .modalWrap {
    margin-top: var(--modal-wrapper-margin-small);
  }

  .modalBlur {
    box-shadow: 0 calc(var(--modal-wrapper-margin-small) - 25px) 16px var(--background-color);
    height: var(--modal-wrapper-margin-small);
  }

  .header,
  .footer {
    left: var(--page-gutter-side-med);
  }

  .info,
  .teaching {
    top: 150px;
    max-height: 67vh;


  }

  .info,
  .teaching {
    padding: calc(var(--page-gutter-side-med));
  }

  .info p,
  .teaching p {
    font-size: 1.4em;
    line-height: 1.5em;
  }

  .info>div,
  .teaching>div {
    height: calc(100vh + var(--modal-wrapper-margin-small) + 200px);
  }

}

@media screen and (max-width: 360px) {
  .modalWrap {
    margin-top: var(--modal-wrapper-margin-xsmall);
  }

  .modalBlur {
    box-shadow: 0 calc(var(--modal-wrapper-margin-xsmall) - 25px) 16px var(--background-color);
    height: var(--modal-wrapper-margin-xsmall);
  }

  .info,
  .teaching {
    top: 170px;
  }

  .info>div,
  .teaching>div {
    height: calc(100vh + var(--modal-wrapper-margin-xsmall) + 170px);
  }

}

@media screen and (max-width: 340px) {
  .modalWrap {
    margin-top: var(--modal-wrapper-margin-xxsmall);
  }

  .modalBlur {
    box-shadow: 0 calc(var(--modal-wrapper-margin-xxsmall) - 25px) 16px var(--background-color);
    height: var(--modal-wrapper-margin-xxsmall);
  }

  .info,
  .teaching {
    top: 180px;
  }

  .info>div,
  .teaching>div {
    height: calc(100vh + var(--modal-wrapper-margin-xxsmall) + 180px);
  }


}

@media screen and (max-width: 340px) {

  .info>div,
  .teaching>div {
    height: calc(100% + var(--modal-wrapper-margin-xxsmall) + 180px);
  }


}