$spacing: (
  0: 0,
  xs: 5px,
  s: 10px,
  m: 20px,
  l: 40px,
  xl: 100px,
  a: auto,
);

@function spacing($size: m) {
  @return map-get($spacing, $size);
}

@mixin m($sizename: "m") {
  margin: spacing($sizename);
}

@mixin headerMargin() {
  margin: var(--page-gutter) 0px 0px 0px;
  padding: 0px 0 var(--category-margin) 0;
}

@mixin p($sizename: "m") {
  padding: spacing($sizename);
}

@mixin ml($sizename: "m") {
  margin-left: spacing($sizename);
}

@mixin pl($sizename: "m") {
  padding-left: spacing($sizename);
}

@mixin mr($sizename: "m") {
  margin-right: spacing($sizename);
}

@mixin pr($sizename: "m") {
  padding-right: spacing($sizename);
}

@mixin mt($sizename: "m") {
  margin-top: spacing($sizename);
}

@mixin pt($sizename: "m") {
  padding-top: spacing($sizename);
}

@mixin mb($sizename: "m") {
  margin-bottom: spacing($sizename);
}

@mixin pb($sizename: "m") {
  padding-bottom: spacing($sizename);
}

@mixin mv($sizename: "m") {
  margin-top: spacing($sizename);
  margin-bottom: spacing($sizename);
}

@mixin pv($sizename: "m") {
  padding-top: spacing($sizename);
  padding-bottom: spacing($sizename);
}

@mixin mh($sizename: "m") {
  margin-left: spacing($sizename);
  margin-right: spacing($sizename);
}

@mixin ph($sizename: "m") {
  // padding-left: spacing($sizename);
  // padding-right: spacing($sizename);
}

@mixin small-only {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin smartphone {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin medium-only {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin medium-below {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin xlarge {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin portrait {
  @media all and (orientation: portrait) {
    @content;
  }
}

@mixin container--full {
  margin-left: 0;
  margin-right: 0;
}

@mixin container {
  margin-left: auto;
  margin-right: auto;
  // max-width: var(--insetMaxWidth);
  width: 100%;

  @media screen and (max-width: 1024px) {
    @include ph;
    max-width: none;
  }
}

@mixin unstyled-list {
  list-style: none;
  margin-left: 0;
  & > li {
    margin-left: 0;
  }
}

@keyframes bounce {
  50% {
    -webkit-transform: scale(0.85) translateY(-30%);
    transform: scale(0.85) translateY(-30%);
  }

  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }
}
@mixin bouncing($duration: 2s) {
  animation: bounce $duration ease infinite;
}

@mixin responsiveFont($size, $line, $margin, $lspacing) {
  font-size: $size;
  line-height: $line;
  margin-bottom: $margin;
  letter-spacing: $lspacing;
}

@mixin navHeader-font {
  // @include responsiveFont(1em, 1em, 1em, 2px);

  @include responsiveFont(4vw, 6vw, 2vw, 1vw);

  @include smartphone {
    @include responsiveFont(5vw, 6vw, 2.5vw, 1.25vw);
  }

  @include small-only {
    @include responsiveFont(6vw, 6vw, 3vw, 1.5vw);
  }

  @include medium {
    @include responsiveFont(1.5em, 1.5em, 0.5em, 0.25em);
  }
}

@mixin navItem-font {
  // @include responsiveFont(.9em, .9em, inherit, -1px);

  @include responsiveFont(3vw, 3vw, auto, -1px);

  @include smartphone {
    @include responsiveFont(4vw, 4vw, auto, -1px);
    // @include responsiveFont(.9em, .9em, auto, -1px);
  }

  @include small-only {
    @include responsiveFont(4vw, 4vw, auto, -1px);
  }

  @include medium {
    @include responsiveFont(1.1em, 1.1em, auto, -1px);
  }
}

@mixin coverLink-font {
  @include responsiveFont(2vw, 2vw, auto, -1px);

  @include smartphone {
    @include responsiveFont(3vw, 3vw, auto, -1px);
  }

  @include small-only {
    @include responsiveFont(3vw, 3vw, auto, -1px);
  }

  @include medium {
    @include responsiveFont(0.7em, 0.9em, auto, -1px);
  }
}

@mixin coverSubtitle-font {
  @include responsiveFont(2.5vw, 2.5vw, auto, -1px);

  @include smartphone {
    @include responsiveFont(4vw, 4vw, auto, -1px);
  }

  @include small-only {
    @include responsiveFont(4vw, 4vw, auto, -1px);
  }

  @include medium {
    @include responsiveFont(0.9em, 0.9em, auto, -1px);
  }
}

@mixin gutterItem-font {
  @include responsiveFont(6vw, 6vw, auto, 0);

  @include smartphone {
    @include responsiveFont(20vw, 10vw, auto, 0);
  }

  @include small-only {
    @include responsiveFont(20vw, 10vw, auto, 0);
  }

  > div > div > div::after {
    // color: red;
    // font-size: 3vw;
    // line-height: 3vw;
  }
}

@mixin footer-font {
  @include responsiveFont(1.5vw, 1.5vw, auto, normal);

  @include smartphone {
    @include responsiveFont(0.6em, 0.6em, auto, normal);
  }

  @include small-only {
    @include responsiveFont(0.4em, 0.4em, auto, normal);
  }

  @include medium {
    @include responsiveFont(0.7em, 0.7em, auto, normal);
  }
}

@mixin subfooter-font {
  @include responsiveFont(1vw, 1vw, auto, normal);

  @include smartphone {
    @include responsiveFont(0.5em, 0.5em, auto, normal);
  }

  @include small-only {
    @include responsiveFont(0.3em, 0.3em, auto, normal);
  }

  @include medium {
    @include responsiveFont(0.6em, 0.6em, auto, normal);
  }
}

@mixin headerWeight {
  font-variation-settings: "wdth" 110, "wght" 100;
  letter-spacing: 0;
}

@mixin activePostTitleWeight {
  font-variation-settings: "wdth" 110, "wght" 150;
  letter-spacing: 0;
}
